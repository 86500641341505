import { Union } from "../fable_modules/fable-library.3.7.17/Types.js";
import { NonEmptyString___UnsafeUnwrap_Z73AD07C, AvailabilityCalendarEvent, AvailabilityCalendarEventId, AppointmentListType, AvailabilityCalendarEvent$reflection } from "../../../shared/Domain.fs.js";
import { AppointmentDetails$reflection } from "../../../shared/DomainHelpers.fs.js";
import { union_type } from "../fable_modules/fable-library.3.7.17/Reflection.js";
import { ofArray, cons, exists, length, sortByDescending, sortBy, tryHead, filter, singleton as singleton_1, toArray, empty, map, choose } from "../fable_modules/fable-library.3.7.17/List.js";
import { useSnackbar } from "notistack";
import { useReact_useMemo_CF4EA67, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { isNullOrWhiteSpace, printf, toConsole } from "../fable_modules/fable-library.3.7.17/String.js";
import { utcNow, fromDate, create } from "../fable_modules/fable-library.3.7.17/DateOffset.js";
import { addMonths, compare, now, toString, fromDateTimeOffset, day, month, year } from "../fable_modules/fable-library.3.7.17/Date.js";
import { create as create_1 } from "../fable_modules/fable-library.3.7.17/TimeSpan.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { weekEndDate, weekStartDate, getDayEnd, getDayStart } from "../components/AppointmentUtils.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { DateRange, CalendarProp$2_Selectable_Z1FBCCD16, CalendarProp$2_DayLayoutAlgorithm_Z3A793B44, CalendarProp$2, CalendarEvent$1 } from "../lib/bindings/BigCalendar.fs.js";
import { createElement } from "react";
import { stringHash, equals, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { px } from "../fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { Height } from "../fable_modules/Fss-lib.Core.1.0.4/css/ContentSize.fs.js";
import { Scheduler as Scheduler_1 } from "../components/Scheduler.fs.js";
import { addDays, setHours, setMinutes } from "date-fns";
import { contains } from "../fable_modules/fable-library.3.7.17/Array.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52, Deferred$1 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { useAuth0 } from "@auth0/auth0-react";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { Page } from "../components/Page.fs.js";
import { Card } from "../../../DesignSystems/DesignSystem.fs.js";

export class AvailabilityCalendarEventType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Availability", "Appointment"];
    }
}

export function AvailabilityCalendarEventType$reflection() {
    return union_type("Availability.AvailabilityCalendarEventType", [], AvailabilityCalendarEventType, () => [[["Item", AvailabilityCalendarEvent$reflection()]], [["Item", AppointmentDetails$reflection()]]]);
}

export function chooseAvailabilityEvents(events) {
    return choose((e) => {
        if (e.tag === 1) {
            return void 0;
        }
        else {
            return e.fields[0];
        }
    }, events);
}

export function Scheduler(schedulerInputProps) {
    let elems;
    const events = schedulerInputProps.events;
    const endDate = schedulerInputProps.endDate;
    const startDate = schedulerInputProps.startDate;
    const api = schedulerInputProps.api;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(map((a) => (new AvailabilityCalendarEventType(0, a)), events));
    const setEvents = patternInput[1];
    const events_1 = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(empty());
    const appointments = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const dateRange = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("week");
    const setCalendarView = patternInput_3[1];
    const calendarView = patternInput_3[0];
    const editEnabledViewModes = ["week", "day", "agenda"];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            if (dateRange == null) {
                toConsole(printf("dateRange is none"));
                return singleton.Zero();
            }
            else {
                const dt = dateRange;
                const startDate_1 = create(year(dt.start), month(dt.start), day(dt.start), 0, 0, 0, create_1(0, 0, 0));
                const endDate_1 = create(year(dt.end), month(dt.end), day(dt.end), 23, 59, 59, create_1(0, 0, 0));
                toConsole(printf("fetching events for period from %A to %A"))(startDate_1)(endDate_1);
                return singleton.Bind(api.GetAdiAvailabilityCalendarEvents(startDate_1, endDate_1), (_arg_1) => {
                    const data = _arg_1;
                    if (data.tag === 1) {
                        const err = data.fields[0];
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        console.error(err);
                        return singleton.Zero();
                    }
                    else {
                        setEvents(map((a_1) => (new AvailabilityCalendarEventType(0, a_1)), data.fields[0]));
                        return singleton.Zero();
                    }
                });
            }
        }), (_arg_2) => {
            const err_1 = _arg_2;
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            console.error(err_1);
            return singleton.Zero();
        })));
    }, [dateRange]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => {
            let patternInput_4;
            if (dateRange == null) {
                toConsole(printf("dateRange is none"));
                patternInput_4 = [startDate, endDate];
            }
            else {
                const dateRange_1 = dateRange;
                patternInput_4 = [getDayStart(dateRange_1.start), getDayEnd(dateRange_1.end)];
            }
            return singleton.Bind(api.GetAppointmentsAndPlaceDescriptionDetailsInRange(fromDate(patternInput_4[0]), fromDate(patternInput_4[1]), void 0, new AppointmentListType(1), new PagingQuery(0, 999), void 0), (_arg_3) => {
                const data_1 = _arg_3;
                if (data_1.tag === 1) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden bij het ophalen van de afspraken. Gelieve support te contacteren. [${data_1.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                }
                else {
                    patternInput_1[1](data_1.fields[0].Data);
                    return singleton.Zero();
                }
            });
        }));
    }, [dateRange]);
    const calenderEvents = useReact_useMemo_CF4EA67(() => toArray(map((e_4) => {
        let patternInput_5, copyOfStruct, copyOfStruct_1;
        return new CalendarEvent$1(false, (calendarView === "month") ? ((patternInput_5 = [(copyOfStruct = fromDateTimeOffset(e_4.StartTime, 0), toString(copyOfStruct, "HH:mm")), (copyOfStruct_1 = fromDateTimeOffset(e_4.EndTime, 0), toString(copyOfStruct_1, "HH:mm"))], `${patternInput_5[0]} - ${patternInput_5[1]}`)) : "", e_4.StartTime, e_4.EndTime, 0, new AvailabilityCalendarEventType(0, e_4));
    }, choose((e_2) => {
        if (e_2.tag === 1) {
            return void 0;
        }
        else {
            return e_2.fields[0];
        }
    }, events_1))), [events_1, calendarView]);
    const appointmentEvents = useReact_useMemo_CF4EA67(() => toArray(map((e_5) => {
        let address;
        if (e_5.tag === 1) {
            const e_7 = e_5.fields[0];
            return new CalendarEvent$1(false, e_7.Appointment.Note, e_7.Appointment.StartTime, e_7.Appointment.EndTime, e_7.AdiId.Value, new AvailabilityCalendarEventType(0, new AvailabilityCalendarEvent(new AvailabilityCalendarEventId(0), e_7.AdiId, e_7.Appointment.StartTime, e_7.Appointment.EndTime)));
        }
        else {
            const e_6 = e_5.fields[0];
            toConsole(printf("appointment events.apt.id %A"))(e_6.Appointment.Id);
            return new CalendarEvent$1(false, (address = e_6.Dossier.Address, `${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.City)}-${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.PostalCode)}`), e_6.Appointment.StartTime, e_6.Appointment.EndTime, e_6.AdiId.Value, new AvailabilityCalendarEventType(1, e_6));
        }
    }, appointments)), [appointments, calendarView]);
    return createElement("div", createObj(ofArray([Feliz_prop__prop_fss_Static_72C268A9(singleton_1(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, px(750)))), (elems = [Scheduler_1([new CalendarProp$2(4, calenderEvents), new CalendarProp$2(3, appointmentEvents), new CalendarProp$2(27, (event, start, end, isSelected) => {
        const baseStyle = {
            className: "",
        };
        const placeDescrStyle = {
            className: "place-descr-event",
        };
        let dossierCode;
        const _arg = event.metaData;
        dossierCode = ((_arg.tag === 1) ? _arg.fields[0].Dossier.Code.Value : "0");
        if (isNullOrWhiteSpace(dossierCode) ? true : (dossierCode === "0")) {
            return placeDescrStyle;
        }
        else {
            return baseStyle;
        }
    }), new CalendarProp$2(29, true), new CalendarProp$2(24, 30), new CalendarProp$2(25, 2), new CalendarProp$2(30, setMinutes(setHours(now(), 7), 0)), new CalendarProp$2(31, setMinutes(setHours(now(), 22), 0)), CalendarProp$2_DayLayoutAlgorithm_Z3A793B44("no-overlap"), new CalendarProp$2(7, (v) => {
        toConsole(printf("view %A"))(v);
        setCalendarView(v);
    }), new CalendarProp$2(10, (event_1, _arg_4) => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            const matchValue = event_1.metaData;
            if (matchValue.tag === 1) {
                return singleton.Return();
            }
            else {
                const metaData_3 = matchValue.fields[0];
                return singleton.Combine((metaData_3.Id.Value === 0) ? singleton.Return() : singleton.Zero(), singleton.Delay(() => singleton.Bind(api.RemoveAvailabilityCalendarEvent(metaData_3.Id, true), (_arg_5) => {
                    if (_arg_5.tag === 1) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${_arg_5.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                    else {
                        setEvents(map((e_9) => (new AvailabilityCalendarEventType(0, e_9)), filter((e_8) => (!equals(e_8.Id, metaData_3.Id)), chooseAvailabilityEvents(events_1))));
                        return singleton.Zero();
                    }
                })));
            }
        }), (_arg_6) => {
            console.error(_arg_6);
            return singleton.Zero();
        })));
    }), new CalendarProp$2(9, (slot) => {
        let s_1;
        let isDoubleClick;
        const matchValue_1 = slot.action;
        switch (matchValue_1) {
            case "click": {
                isDoubleClick = true;
                break;
            }
            case "select": {
                isDoubleClick = false;
                break;
            }
            default: {
                isDoubleClick = true;
            }
        }
        if (contains(calendarView, editEnabledViewModes, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        }) && (!isDoubleClick)) {
            const overlappingEvents = filter((e_11) => {
                if (compare(e_11.StartTime, slot.end) <= 0) {
                    return compare(slot.start, e_11.EndTime) <= 0;
                }
                else {
                    return false;
                }
            }, chooseAvailabilityEvents(events_1));
            const startSlot = tryHead(sortBy((e_12) => e_12.StartTime, overlappingEvents, {
                Compare: compare,
            }));
            const endSlot = tryHead(sortByDescending((e_13) => e_13.EndTime, overlappingEvents, {
                Compare: compare,
            }));
            let startTime;
            if (startSlot == null) {
                startTime = slot.start;
            }
            else {
                const s = startSlot;
                const st = (compare(s.StartTime, slot.start) > 0) ? slot.start : s.StartTime;
                const et = (compare(s.EndTime, slot.end) < 0) ? slot.end : s.EndTime;
                startTime = st;
            }
            const newEvent = {
                EndTime: (endSlot == null) ? slot.end : ((s_1 = endSlot, (compare(s_1.EndTime, slot.end) < 0) ? slot.end : s_1.EndTime)),
                StartTime: startTime,
            };
            startImmediate(singleton.Delay(() => {
                const createEvent = () => singleton.Delay(() => singleton.Bind(api.CreateAvailabilityCalendarEvent(newEvent), (_arg_7) => {
                    if (_arg_7.tag === 1) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${_arg_7.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Return(void 0);
                    }
                    else {
                        return singleton.Return(_arg_7.fields[0]);
                    }
                }));
                return (length(overlappingEvents) > 0) ? singleton.Combine(singleton.For(overlappingEvents, (_arg_9) => {
                    let e_14;
                    startImmediate((e_14 = _arg_9, singleton.Delay(() => singleton.Bind(api.RemoveAvailabilityCalendarEvent(e_14.Id, false), (_arg_8) => {
                        if (_arg_8.tag === 1) {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${_arg_8.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton.Zero();
                        }
                        else {
                            toConsole(printf("removed %i"))(e_14.Id.Value);
                            return singleton.Zero();
                        }
                    }))));
                    return singleton.Zero();
                }), singleton.Delay(() => singleton.Bind(createEvent(), (_arg_10) => {
                    if (_arg_10 == null) {
                        return singleton.Zero();
                    }
                    else {
                        const event_4 = _arg_10;
                        const eIds = map((i) => i.Id, overlappingEvents);
                        setEvents(cons(new AvailabilityCalendarEventType(0, event_4), map((a_3) => (new AvailabilityCalendarEventType(0, a_3)), filter((e_16) => exists((o) => (!(o.Value === e_16.Id.Value)), eIds), chooseAvailabilityEvents(events_1)))));
                        return singleton.Zero();
                    }
                }))) : singleton.Bind(createEvent(), (_arg_11) => {
                    if (_arg_11 == null) {
                        return singleton.Zero();
                    }
                    else {
                        setEvents(cons(new AvailabilityCalendarEventType(0, _arg_11), events_1));
                        return singleton.Zero();
                    }
                });
            }));
        }
        else {
            if (!isDoubleClick) {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Beschikbaarheden kunnen niet toegevoegd worden vanuit het maandoverzicht. Ga naar het weekoverzicht om beschikbaarheden toe te voegen.", SnackbarProp_Variant_Z609E1E86("warning"), new EnqueueSnackbarOption(1, false));
            }
        }
    }), CalendarProp$2_Selectable_Z1FBCCD16(true), new CalendarProp$2(6, (datetime, view, a_4) => {
        const patternInput_6 = (view === "week") ? [weekStartDate(datetime), weekEndDate(datetime)] : ((view === "month") ? [datetime, addMonths(datetime, 1)] : ((view === "work_week") ? [datetime, addDays(datetime, 5)] : ((view === "agenda") ? [datetime, datetime] : [datetime, datetime])));
        const range = new DateRange(patternInput_6[0], patternInput_6[1]);
        setCalendarView(view);
        patternInput_2[1](range);
    }), new CalendarProp$2(44, "week")])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function LoadScheduler(api) {
    const patternInput = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const auth = useAuth0();
    const startDate = useFeliz_React__React_useState_Static_1505(fromDate(addDays(utcNow(), -7)))[0];
    const endDate = useFeliz_React__React_useState_Static_1505(fromDate(addDays(utcNow(), 7)))[0];
    const data_1 = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAdiAvailabilityCalendarEvents(startDate, endDate), []);
    return createElement(DisplayDeferred, {
        data: data_1,
        view: (events) => createElement(Scheduler, {
            api: api,
            startDate: fromDateTimeOffset(startDate, 0),
            endDate: fromDateTimeOffset(endDate, 0),
            events: events,
        }),
    });
}

export function Availability(availabilityInputProps) {
    const api = availabilityInputProps.api;
    const currentPage = availabilityInputProps.currentPage;
    return createElement(Page, {
        currentPage: currentPage,
        title: "Beschikbaarheid",
        actions: [],
        children: [Card([createElement(LoadScheduler, api)])],
    });
}

